import * as React from "react";
import { Link } from 'gatsby';
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

const ThumbnailLinkGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 20px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ThumbnailLinkContainer = styled.div`
  text-align: center;
  border: 3px solid #1E1E1E;
  border-radius: 8px;
  background: #225C4C;
`;

const ThumbnailTitle = styled.span`
  color: #FFF;
  display: block;
  margin: 10px 0px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

interface ThumbnailLinkGridProps {
  links: { link: string, title: string; image?: IGatsbyImageData }[];    
}

export const ThumbnailLinkGrid: React.FC<ThumbnailLinkGridProps> = ({ links }) => {
  return (
    <ThumbnailLinkGridContainer>
      {links.map(link =>
        <StyledLink to={link.link}>
          <ThumbnailLinkContainer>
            <GatsbyImage 
              image={link.image}
              alt={link.title} 
              style={{ 
                width: "100%",
                borderRadius: "8px"
              }} 
              imgStyle={{
                borderRadius: "5px"
              }}
            />
            <ThumbnailTitle>{link.title}</ThumbnailTitle>
          </ThumbnailLinkContainer>
        </StyledLink>
      )}
    </ThumbnailLinkGridContainer>
  );
}
