import * as React from "react";
import { Layout } from "../components/Layout";
import { graphql } from 'gatsby';
import { PageTitle } from "../components/PageTitle";
import { ThumbnailLinkGrid } from "../components/ThumbnailLinkGrid";
import { getImage } from "gatsby-plugin-image";
import { GoogleMap } from "../components/Map";
import { lighthouseNameToUrl, toURLEncodedName } from "../lib/lighthouses";

export const Head = () => 
  <>
    <title>Lighthouses of the world | Worldwide Lighthouses</title>
    <meta name="description" content="All of the lighthouses that worldwide lighthouses has kept record of. Find out about the lights and the lighthouse keepers and communities who kept them running." />
  </>;

const LighthousesPage = ({ data }) => {
  const lighthouses = data.lighthouses.edges.map(edge => edge.node);
  const images = data.images.edges;

  return (
    <Layout>
      <PageTitle title="Lighthouses" />
      <GoogleMap
        lighthousePins={
          lighthouses
            .map(l => l.frontmatter)
            .filter(fm => fm.lat && fm.lng)
            .map(fm => ({ 
              lat: fm.lat, 
              lng: fm.lng, 
              url: lighthouseNameToUrl(fm.name), 
              name: fm.name 
            }))
        }
        defaultCenter={{
          lat: 54.5, 
          lng: -3.22758000
        }}
        googleMapsApiKey="AIzaSyBI3WITyvF4JEOEjTYFpnVg6MqODmeOgoU"
        zoom={5}
      />
      <ThumbnailLinkGrid links={lighthouses.map(lighthouse => { 
        const urlEncodedName = toURLEncodedName(lighthouse.frontmatter.name);
        const imageEdge = images.find(image => image.node.relativeDirectory === urlEncodedName);
        const image = imageEdge ? getImage(imageEdge.node) : null;

        return { 
          link: lighthouseNameToUrl(lighthouse.frontmatter.name), 
          title: lighthouse.frontmatter.name,
          image
        };
      })} />
    </Layout>
  )
}

export const Query = graphql`
  query LighthousesQuery {
    lighthouses: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/content/lighthouses/.*.md$/"}}
      sort: { order: ASC, fields: frontmatter___name }
    ) {
      edges {
        node {
          frontmatter {
            name,
            lat,
            lng
          }
          id
        }
      }
    }
    images: allFile(
      filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" } }
      sort: { order:ASC fields: absolutePath }
    ) {
      edges {
        node {
          relativeDirectory,
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 350
              height: 250
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export default LighthousesPage;